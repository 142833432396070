import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import * as environment from "environment";
import { SEX } from "@energylab/shared-components/utils/sex";
import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { emailWhitelistedCreator } from "@energylab/shared-components/utils/emailWhitelistedCreator";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import { getPasswordRules } from "@energylab/shared-components/utils/passwordRule";
import { RegisterConfig } from "@energylab/shared-components/constants/config/registerConfig";
import { UserPrivacyInput } from "@energylab/shared-components/models/graphql/types";
import { locationOptions } from "./locationOptions";

const newRegisterStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    validationRules: [
                        {
                            type: "email",
                            message: <FormatMessage id="auth.error.invalidEmail" />
                        },
                        {
                            validatorBuilder: emailWhitelistedCreator,
                            message: <FormatMessage id="error.emailNotWhitelisted" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    required: true,
                    validationRules: getPasswordRules(environment.password)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.companyCodeIntro",
                    type: "p",
                    property: "companyCodeIntro",
                    hide: true
                },
                {
                    title: "form.companyCode",
                    type: "text",
                    property: "companyCode",
                    hide: true
                },
                {
                    title: "form.supportMail",
                    type: "p",
                    property: "supportMail",
                    hide: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: [
                        { title: <FormatMessage id="male" />, value: SEX.male },
                        { title: <FormatMessage id="female" />, value: SEX.female },
                        { title: <FormatMessage id="intersexual" />, value: SEX.other }
                    ],
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                        }
                    ]
                },
                {
                    title: "form.location",
                    type: "cascader",
                    property: "location",
                    required: true,
                    options: locationOptions
                },
                {
                    title: "form.language",
                    type: "select",
                    options: (environment.languages || []).map(locale => (
                        {
                            title: <FormatMessage id={`locale.${locale}`} />,
                            value: locale
                        }
                    )),
                    property: "locale",
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.useNickname",
                    type: "checkbox",
                    property: "useNickname"
                },
                {
                    title: "form.useNickname",
                    type: "text",
                    property: "nickname",
                    showWhen: {
                        property: "useNickname",
                        valueIs: true
                    },
                    required: true
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};

export const registerConfig: RegisterConfig = {
    backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1606291172/Total/E2020_6370_foto_fade-out-white-bg.png",
    requireEmailValidation: false,
    structure: newRegisterStructure,
    formToValues: (values, originalValues) => {
        const originalUser = (originalValues ? originalValues.profile : {}) || {};
        const originalProfile = originalUser.profile;
        const originalPrivacy: Partial<UserPrivacyInput> = originalUser.privacy || {};

        const {
            password,
            companyCode,
            givenName,
            familyName,
            email,
            locale,
            avatar,
            publicDisplayNameType,
            nickname,
            showActivities,
            showAsNewMember,
            privacy,
            disclaimer,
            cookyPolicy,
            ...profileValues
        } = values;

        const location = Array.isArray(values.location) ? values.location.join(" ") : values.location;

        return {
            email: email as string,
            password: password as string,
            companyCode: companyCode as string,
            profile: {
                ...originalUser,
                givenName: givenName as string,
                familyName: familyName as string,
                email: email as string,
                locale: locale as string,
                avatar: avatar as string,
                profile: {
                    ...originalProfile,
                    ...profileValues,
                    location,
                    hasAcceptedAllPolicies: Boolean(privacy && disclaimer && cookyPolicy)
                },
                privacy: {
                    ...originalPrivacy,
                    publicDisplayNameType: publicDisplayNameType as string || "fullname",
                    nickname: values.nickname ? nickname as string : undefined,
                    showActivities: Boolean(showActivities),
                    showAsNewMember: Boolean(showAsNewMember)
                }
            }
        };
    }
};
