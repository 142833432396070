// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./HelveticaNeueLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./HelveticaNeueBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./HelveticaNeueMedium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./HelveticaNeueThin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: HelveticaNeue;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n    font-weight: 300;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n    font-weight: bold;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n    font-weight: 200;\n}", "",{"version":3,"sources":["webpack://./src/style/fonts/HelveticaNeue.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAAuD;IACvD,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,+DAAsD;IACtD,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,+DAAwD;IACxD,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,+DAAsD;IACtD,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: HelveticaNeue;\n    src: url(\"./HelveticaNeueLight.ttf\") format('truetype');\n    font-weight: 300;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(\"./HelveticaNeueBold.ttf\") format('truetype');\n    font-weight: bold;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(\"./HelveticaNeueMedium.ttf\") format('truetype');\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: HelveticaNeue;\n    src: url(\"./HelveticaNeueThin.ttf\") format('truetype');\n    font-weight: 200;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
