import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { CascaderOptionType } from "antd/lib/cascader";

export const locationOptions: CascaderOptionType[] = [
    {
        label: <FormatMessage id="Brussel" />,
        value: "Brussel",
        children: [
            { label: <FormatMessage id="Retail" />, value: "Retail" },
            { label: <FormatMessage id="Specialiteiten" />, value: "Specialiteiten" },
            { label: <FormatMessage id="Commercial sales" />, value: "Commercial sales" },
            { label: <FormatMessage id="Admin/fin en IT" />, value: "Admin/fin en IT" },
            { label: <FormatMessage id="Logistiek" />, value: "Logistiek" },
            { label: <FormatMessage id="Supply/aankoop" />, value: "Supply/aankoop" },
            { label: <FormatMessage id="Support (HR/HSEQ/JUR)" />, value: "Support (HR/HSEQ/JUR)" },
            { label: <FormatMessage id="Mobility/Fleet services" />, value: "Mobility/Fleet services" }
        ]
    },
    {
        label: <FormatMessage id="Ertvelde" />,
        value: "Ertvelde",
        children: [
            { label: <FormatMessage id="Arbeiders" />, value: "Arbeiders" },
            { label: <FormatMessage id="Bedienden" />, value: "Bedienden" }
        ]
    },
    {
        label: <FormatMessage id="Schoten" />,
        value: "Schoten"
    },
    {
        label: <FormatMessage id="Luxemburg" />,
        value: "Luxemburg"
    },
    {
        label: <FormatMessage id="AS24" />,
        value: "AS24"
    },
    {
        label: <FormatMessage id="Servauto" />,
        value: "Servauto"
    },
    {
        label: <FormatMessage id="Proxifuel" />,
        value: "Proxifuel"
    },
    {
        label: <FormatMessage id="Proxifleet" />,
        value: "Proxifleet"
    },
    {
        label: <FormatMessage id="RC" />,
        value: "RC",
        children: [
            { label: <FormatMessage id="POL" />, value: "POL" },
            { label: <FormatMessage id="RBE" />, value: "RBE" },
            { label: <FormatMessage id="RHC" />, value: "RHC" },
            { label: <FormatMessage id="RPO" />, value: "RPO" },
            { label: <FormatMessage id="FIN" />, value: "FIN" },
            { label: <FormatMessage id="DSI" />, value: "DSI" },
            { label: <FormatMessage id="CSBEL" />, value: "CSBEL" },
            { label: <FormatMessage id="AUTRES" />, value: "AUTRES" }
        ]
    },
    {
        label: <FormatMessage id="TGITS" />,
        value: "TGITS"
    },
]