/* eslint-disable linebreak-style */
import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { UpdateProfileVariables } from "@energylab/shared-components/models/graphql/types";
import { routeConfig } from "@energylab/shared-components/constants/config/routeConfig";
import { profileStructure } from "./profileStructure";

export const profileConfig: ProfileConfig = {
    structure: profileStructure,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "location",
        "sex"
    ],
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }

        const location = Array.isArray(values.location) ? values.location.join(" ") : values.location;

        return {
            profile: {
                ...originalValues,
                locale: String(values.locale) || routeConfig.defaultLocale,
                givenName: String(values.givenName),
                familyName: String(values.familyName),
                profile: {
                    ...originalValues.profile,
                    notifications: values.notifications,
                    nationality: values.nationality,
                    birthDate: values.birthDate,
                    sex: values.sex,
                    location
                },
                privacy: {
                    ...originalValues.privacy
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};
        const privacy = values.privacy || {};

        const location = Array.isArray(profile.location)
            ? profile.location
            : profile.location.split(" ");

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            birthDate: profile.birthDate,
            sex: profile.sex,
            nationality: profile.nationality,
            location,
            email: values.email,
            publicDisplayNameType: privacy.publicDisplayNameType,
            nickname: privacy.nickname,
            locale: values.locale
        };
    }
};
