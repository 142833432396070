import { RedirectType } from "@energylab/shared-components/routes/routes";
import { lazy } from "react";

const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));
const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/benefits/contentCardOverview/contentCardOverviewContainer"));

export const ROUTES = [
    {
        locales: {
            nl: "ambassadeurs"
        },
        path: "ambassadors",
        element: <ContentContainer type="ambassadors_intro" />
    },
    {
        path: "totallyfit",
        element: <ContentContainer type="totallyfit" />
    },
    {
        path: "webshop",
        element: <ContentCardOverviewContainer introKey="benefits" metaDataKey="benefits" contentType="benefit" />
    }
];

export const REDIRECTS: RedirectType[] = [];
